@import "../../scss/base.scss";

.container {
  position: relative;

  &.hasErrorMessage {
    padding-bottom: 25px;
    @include mobile{
      padding-bottom: 0px;
    }
  }

  &.inlineBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
   justify-content: space-evenly;

    .option {
      box-sizing: border-box;
      width: calc((100% - 25px) / 2);
      height: 50px;
      padding: 0 25px;
      border-radius: 10px;
      border: 0.5px solid #393939;
      opacity: 0.9;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
     margin-right: 10px;
      @include mobile {
        width: calc((100% - 15px) / 2);
        height: 44px;
        padding: 0 5px;
        font-size: 14px;
      }
      &.three {
        width: calc((100% - 25px) / 3);
        @include mobile {
          width: calc((100% - 15px) / 3);
        }
      }
      &.active {
        opacity: 1;
        border: 2px solid #00ad66;
      }

      > .checkbox {
        display: none;
      }
    }
  }

  &.inline {
    .option {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }

      .checkbox {
        box-sizing: border-box;
        width: 25px;
        min-width: 25px;
        height: 25px;
        margin-right: 8px;
        border-radius: 12.5px;
        border: 0.5px solid #393939;
        &.active {
          background-color: #00ad66;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("/search/check-mark-white.svg");
          border: none;
        }
      }
    }
    .option:last-child {
      margin-bottom: 0;
    }
  }

  .option {
    font: 16px Circular Std Book, Helvetica, Arial, sans-serif;
    color: #393939;
    cursor: pointer;
    @include mobile {
      font-size: 14px;
    }
    &.bold {
      font-weight: bold;
    }
    &.warning{
      border: 0.5px solid #FF1E57;
    }
  }

  .error {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 10px;
    @include mobile{
      bottom: -20px;
    }

    .icon {
      height: 18px;
      width: 18px;
      margin-right: 5px;
      background-image: url("/puppy/error.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .message {
      font-family: Circular Std Book;
      font-size: 14px;
      line-height: 18px;
      color: #ff1e57;
    }
  }
}
