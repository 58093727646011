@import "../../scss/base.scss";

.container {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  &.hasErrorMessage {
    padding-bottom: 25px;
    @include mobile{
      padding-bottom: 0;
    }
  }
  &.error {
    input {
      border-color: #ff1e57;
    }
  }

  input {
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    padding: 0 25px;
    border-radius: 10px;
    border: 1px solid #393939;
    outline: none;
    background: #FFFFFF;
    font: 18px Circular Std Book, Helvetica, Arial, sans-serif;
    color: #393939;
    @include mobile {
      font-size: 16px;
    }
  }

  .error {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
   @include mobile{
    position: relative;
    bottom: -8px;
   }
    .icon {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      background-image: url("/puppy/error.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .message {
      font-family: Circular Std Book;
      font-size: 14px;
      line-height: 18px;
      color: #ff1e57;
    }
  }
}
