@import '../../../scss/base.scss';

.breedStatePuppyCard {
  margin: 0;

  .cardImage {
    position: relative;
    padding-bottom: 100%; /* This will make the height equal to the width */
    border-radius: 8px;
    background: $baseLightGrey;
    margin-bottom: 3px;
  }

  .location {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: $baseGreenLight;
    border-radius: 0 0 8px 8px;
    .locationInfo {
      display: flex;
      align-items: center;
      font-family: $mawoo-font-bold;
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $baseBlack;
      height: 30px;
      @include mobile {
        font-size: 12px;
        line-height: 15px;
      }
      .locationImg {
        width: 14px;
        height: 16px;
        margin-right: 5px;
        @include mobile {
          width: 10px;
          height: 12px;
        }
      }
    }
  }
  .dogInfo {
    display: flex;
    justify-content: space-between;

    .name {
      text-decoration: none;
      cursor: pointer;
    }
    .name,
    .price {
      font-family: $mawoo-font-bold;
      font-size: 18px;
      line-height: 23px;
      margin: 0;
      @include mobile {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .attribute {
      font-family: $mawoo-font-book;
      font-size: 16px;
      line-height: 23px;
      margin: 0;
      @include mobile {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .name,
    .attribute {
      color: $baseBlack;
    }
    .strike {
      text-decoration: line-through;
    }
  }
}
