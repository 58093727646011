@import '../../scss/base.scss';

.modalHolder {
  width: 387px;
  margin: 10px auto;


  @include mobile {
    width: auto;
    margin: 20px;
  }

  .title {
    font-size: 35px;
    font-family:  Circular Std Book, Helvetica, Arial, sans-serif;
    letter-spacing: 0px;
    color: #393939;
    font-weight: bold;
    @include mobile{
      font-size: 25px;
    }
  }
  .subTitle{
    font-size: 18px;
    font-family:  Circular Std Book, Helvetica, Arial, sans-serif;
    letter-spacing: 0px;
    color: #393939;
    margin: 10px 0 30px;
    @include mobile{
      font-size: 16px;
    }
  }

  .row {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .errorMessage {
    font: 14px/35px Circular Std Book, Helvetica, Arial, sans-serif;
    color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    .icon {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      background-image: url("/puppy/error.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.show {
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url("/not_view.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  right: 15px;
  top:10px
}

.notShow {
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url("/view.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  right: 15px;
  top:10px
}