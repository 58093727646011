@import '../../scss/base.scss';

.inputContainer {
    position: relative;
    margin-top: 10px;
    label {
        font-family: Circular Std bld, Arial, Helvetica, sans-serif;
        font-size: 18px;
        line-height: 23px;
        /* v2/black */
        color: $baseBlack;
        @include mobile{
            font-size: 16px;
            line-height: 20px;
        }
    }
    .simpleInput {
        margin-top: 15px;
    }
    .showError {
        border: 1px solid #FF1E57!important;
    }
    .icon {
        position: absolute;
        top: 50px;
        right: 14px;
        width: 24px;
        height: 24px;
        background-image: url("/error.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .error {
        height: 20px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        >.message {
            font: 14px Circular Std Book, Helvetica, Arial, sans-serif;
            color: #ff1e57;

            @include mobile {
                font-size: 12px;
            }
        }
    }
}