@import "../../scss/base.scss";

.container {
  width: 100%;
  position: relative;

  /* DEFAULT LAYOUT */
  .select {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 0 25px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #393939;

    &.active {
      border: 2px solid #00ad66;
    }

    .label {
      font: 16px Circular Std Book, Helvetica, Arial, sans-serif;
      color: #393939;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: "ellipsis";

      @include mobile {
        font-size: 14px;
      }
    }

    .icon {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin-left: 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  /* Input */
  .input {
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    border-radius: 25px;
    background: #fffaf3;
    display: flex;
    align-items: center;

    input {
      padding: 0;
      border: none;
      outline: none;
      background: none;
      font: 18px Circular Std Book, Helvetica, Arial, sans-serif;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  /* Error */
  .showError {
    border: 1px solid #ff1e57;
  }

  .error {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;

    .icon {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      background-image: url("/puppy/error.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .message {
      font-family: Circular Std Book;
      font-size: 14px;
      line-height: 18px;
      color: #ff1e57;
    }
  }

  /* OPTIONS */
  .optionsContainer {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid rgb(57, 57, 57);
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
    position: absolute;
    z-index: 2;
    top: calc(100% + 15px);

    .arrow {
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      border-radius: 4px 0 0 0;
      transform: rotate(45deg);
      border-top: 0.5px solid rgba(57, 57, 57);
      border-left: 0.5px solid rgba(57, 57, 57);
      background: #ffffff;
      position: absolute;
      top: -7px;
      left: 40px;
    }

    .options {
      box-sizing: border-box;
      max-height: 320px;
      padding: 10px 0;
      overflow-y: scroll;

      /* Creatable box */
      &.isCreatable {
        max-height: 270px;
      }

      .option {
        box-sizing: border-box;
        width: 100%;
        padding: 10px 25px;
        display: flex;
        align-items: center;
        cursor: pointer;

        /* For 'radio-rounded' and 'radio-square' themes */
        .checkbox {
          box-sizing: border-box;
          width: 25px;
          min-width: 25px;
          height: 25px;
          margin-right: 15px;
          border: 0.5px solid #393939;
        }
      

        &.radio-rounded {
          .checkbox {
            border-radius: 12.5px;
          }
        }

        &.radio-square {
          .checkbox {
            border-radius: 3px;
          }
        }

        &.current {
          .checkbox {
            background-color: $baseGreen;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("/search/check-mark-white.svg");
            border: none;
          }
        }

        .label {
          width: 100%;
          color: #393939;
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 18px;

          @include mobile {
            font-size: 14px;
          }
        }

        &.disabled {
          cursor: not-allowed;

          .label {
            opacity: 0.5;
          }

          &.current {
            cursor: pointer;

            .label {
              opacity: 1;
            }
          }
        }

        /* Default check mark */
        .check {
          width: 15px;
          min-width: 15px;
          height: 15px;
          margin-left: 15px;
          background-image: url("/check-black.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          display: none;
        }

        &.current {
          .check {
            display: unset;
          }
        }
      }

      /* OPTION HOVER */
      .option:hover {
        /* Default hover */
        background: rgb(166, 232, 205, 0.7);

        &.current {
          &.unmarkable {
            .check {
              background-image: url("/close-black.svg");
            }
          }
        }

        /* For 'radio-rounded' and 'radio-square' themes */
        &.radio-rounded,
        &.radio-square {
          background: none;

          .label {
            color: $baseGreen;
          }
        }
      }

      .empty {
        padding: 5px 25px;
        display: flex;
        align-items: center;

        .icon {
          height: 26px;
          width: 26px;
          margin-right: 10px;
          background-image: url("/puppy/error.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .message {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 16px;
          color: #393939;
          text-align: center;
        }
      }
    }
   
  }

  &.hasErrorMessage {
    padding-bottom: 25px;

    .optionsContainer {
      top: calc(100% - 10px);
    }
  }
}
