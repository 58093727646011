@import '../../scss/base.scss';

.trustpilot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .title,
  .text {
    color: $baseBlack;
    font-family:
      Circular Std bld,
      Helvetica,
      Arial,
      sans-serif;
    margin: 0 !important;
    font-size: 16px;
  }

  a {
    .starts {
      background-image: url('/truspilot/five-starts-truspilot.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 150px;
      height: 30px;
      margin: 0 10px;
      @include tablet {
        width: 130px;
        height: 24px;
      }
    }
    .logo {
      background-image: url('/truspilot/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 90px;
      height: 22px;
      margin: 0 10px;
      @include mobile {
        margin: 0;
      }
    }
  }
}
