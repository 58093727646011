@import '../../scss/base.scss';

.landingCoverContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @include mobile {
    align-items: unset;
  }

  .backgroundCover {
    height: 340px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    /* BG */
    background:
      radial-gradient(
          238.54% 379.33% at 51.74% 42.27%,
          rgba(255, 243, 225, 0.8) 0%,
          rgba(0, 214, 126, 0.8) 47.33%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #ffffff;
    background-position: center;
    background-repeat: no-repeat;

    @include mobile {
      display: none;
      height: unset;
      background: #ffffff;
    }
  }
  .container {
    position: absolute;
    z-index: 10;
    top: 10px;
    display: flex;
    flex-direction: column;
    max-width: 1130px;
    width: 100%;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
      position: unset;
      z-index: unset;
      left: unset;
      right: unset;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }

    .breadcrumbs {
      box-sizing: border-box;
      width: 100%;
      max-width: calc(1085px + 60px);
      margin: 0 auto;
      padding: 0 0 10px 20px;

      @include mobile {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        max-width: calc(1085px + 60px);
        margin: 8px auto;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
      }
      .steps {
        font-family: Circular Std Book;
        font-size: 16px;
        line-height: 20px;
        color: #393939;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0px;
        font-weight: 400px;
        opacity: 0.8;
        @include mobile {
          font-size: 14px;
        }
      }

      .step {
        font-family: Circular Std Book;
        font-size: 16px;
        line-height: 20px;
        color: #393939;
        text-decoration: none;
        @include mobile {
          font-size: 14px;
        }
      }

      .arrowRight {
        width: 6px;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 8px;
        margin-right: 8px;
      }

      .arrowRight {
        background-image: url('/puppy/arrow-right-black.svg');
      }
    }
    .cardsContainer {
      display: flex;
      padding: 0 20px;

      @include mobile {
        position: unset;
        z-index: unset;
        left: unset;
        right: unset;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
      }

      .breedCardSummary {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 665px;
        height: 370px;
        margin-right: 15px;
        padding: 15px 30px;
        box-sizing: border-box;
        /* v2/white */
        background: #ffffff;
        /* bubble shadow */
        box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
        border-radius: 15px;

        @include mobile {
          width: 100%;
          height: 175px;
          margin: 0;
          border-radius: unset;
          text-align: center;
          box-shadow: unset;
          padding: 0 30px;
        }

        h1 {
          color: #009f66;
          font-family:
            Circular Std Bld,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 35px;
          line-height: 44px;
          text-align: center;
          margin-top: 0px;
          margin-bottom: 0;

          @media screen and (max-width: 900px) {
            max-width: 80%;
          }

          @media screen and (max-width: 768px) {
            max-width: 100%;
          }

          @include mobile {
            font-size: 25px;
            line-height: 32px;
            padding: 1rem 0;
          }
        }

        .description {
          font-family:
            Circular Std Bld,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color: #393939;
          margin: 10px auto 30px;
          max-width: 85%;

          @media screen and (max-width: 886px) {
            max-width: 100%;
          }

          @include mobile {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
          }
        }

        .badgeContainer {
          display: flex;
          align-items: baseline;
          justify-content: space-around;

          @include mobile {
            display: none;
          }

          .badge {
            width: calc(100% / 3);
            text-align: center;

            @include tablet {
              width: 150px;
            }

            @include mobile {
              width: 100px;
            }

            h3 {
              font-family:
                Circular Std Bld,
                Helvetica,
                Arial,
                sans-serif;
              font-size: 16px;
              line-height: 20px;
              color: #393939;
              text-align: center;
              margin: 10px 0 0 0;

              @include mobile {
                font-size: 12px;
                line-height: 15px;
              }
            }

            img {
              width: 60px;

              @include mobile {
                width: 55px;
              }
            }
          }
        }

        .trustpilot {
          position: absolute;
          width: 100%;
          margin-right: auto;
          margin-left: auto;
          bottom: 20px;

          @include tablet {
            bottom: 18px;
          }

          @include mobile {
            bottom: 0;
          }
        }
      }

      .desktopImageHolder {
        img {
          box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
          border-radius: 15px;
        }

        @include mobile {
          display: none;
        }
      }

      .mobileCoverImg {
        display: none;
        @include mobile {
          display: block;
        }
      }
    }
  }
  .mobileCoverImg {
    display: none;
    @include tablet {
      display: contents;
    }
  }
}
