@import "../../scss/base.scss";

.container {
  width: 100%;
  position: relative;
  margin-top: -15px;

  /* DEFAULT LAYOUT */
  // .select {
  //   box-sizing: border-box;
  //   width: 100%;
  //   height: 50px;
  //   padding: 0 25px;
  //   border-radius: 25px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   border: 0.5px solid #393939;

  //   .label {
  //     font: 18px Circular Std Book, Helvetica, Arial, sans-serif;
  //     color: #393939;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 1;
  //     -webkit-box-orient: vertical;
  //     overflow: hidden;
  //     text-overflow: "ellipsis";

  //     @include mobile {
  //       font-size: 14px;
  //     }
  //   }

  //   .icon {
  //     width: 15px;
  //     min-width: 15px;
  //     height: 15px;
  //     margin-left: 15px;
  //     background-size: contain;
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     cursor: pointer;
  //   }
  // }

  /* Input */
  .input {
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;

    input {
      padding: 0;
      border: none;
      outline: none;
      background: none;
      font: 18px Circular Std Book, Helvetica, Arial, sans-serif;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  /* Error */
  .showError {
    border: 1px solid #ff1e57!important;
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;

    .icon {
      position: absolute;
      top:12px;
      right: 15px;
      height: 24px;
      width: 24px;
      background-image: url("/puppy/error.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .message {
      font-family: Circular Std Book;
      font-size: 14px;
      line-height: 18px;
      color: #ff1e57;
    }
  }

  /* FILTERABLE LAYOUT */
  .filterable {
    // padding-left: 30px;
    padding-right: 15px;
    justify-content: space-between;

    input {
      width: calc(100% - 45px);
    }

    .clear {
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-radius: 15px;
      cursor: pointer;
    }
  }

  /* FILTERABLE + CREATABLE LAYOUT */
  .multiple {
    justify-content: flex-start;

    .scroll, .square {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* IE and Edge */
     
      .selected, .black {
        box-sizing: border-box;
        height: 30px;
        padding: 0 15px;
        margin-top: 10px;
        margin-right: 10px;
        background: #a6e8cd;
        border-radius: 60px;
        display: flex;
        align-items: center;

        .label {
          // max-width: 60px;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #393939;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @include mobile{
            font-size: 14px;
            line-height: 18px;
          }
        }

        .clear {
          width: 12px;
          height: 12px;
          margin-left: 10px;
          background-image: url("/search/close.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
      .black{
        background-color: white;
        border: 2px solid #393939;
        color: #393939;
        
        
      }

      input {
        width: 100%;
        min-width: 100px;

        &.margin {
          margin-left: 15px;
        }
      }
    }
    .square{
      width: 100%;
      border: #393939 2px dashed;
      border-radius: 10px;
      padding: 0 15px 15px;
      margin: 10px;
    }

    .scroll::-webkit-scrollbar {
      display: none;
      /* Chrome, Safari and Opera */
    }
  }

  /* OPTIONS */
  .optionsContainer {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid rgb(57, 57, 57);
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
    position: absolute;
    z-index: 2;
    top: auto;
    margin: 10px;

    .arrow {
      box-sizing: border-box;
      width: 12px;
      height: 12px;
      border-radius: 4px 0 0 0;
      transform: rotate(45deg);
      border-top: 0.5px solid rgba(57, 57, 57);
      border-left: 0.5px solid rgba(57, 57, 57);
      background: #ffffff;
      position: absolute;
      top: -7px;
      left: 40px;
    }

    .options {
      box-sizing: border-box;
      max-height: 320px;
      padding: 10px 0;
      overflow-y: scroll;

      /* Creatable box */
      &.isCreatable {
        max-height: 270px;
      }

      .option {
        box-sizing: border-box;
        width: 100%;
        padding: 10px 25px;
        display: flex;
        align-items: center;
        cursor: pointer;

        /* For 'hasImage' theme */
        .image {
          width: 65px;
          min-width: 65px;
          height: 65px;
          margin-right: 15px;
          border-radius: 4px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;

          img {
            border-radius: 4px;
          }
        }

        .label {
          width: 100%;
          color: #393939;
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 18px;

          @include mobile {
            font-size: 14px;
          }
        }

        &.disabled {
          cursor: not-allowed;

          .label {
            opacity: 0.5;
          }

          &.current {
            cursor: pointer;

            .label {
              opacity: 1;
            }
          }
        }

        /* Default checkmark */
        .check {
          width: 15px;
          min-width: 15px;
          height: 15px;
          margin-left: 15px;
          background-image: url("/check-black.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          display: none;
        }

        &.current {
          .check {
            display: unset;
          }
        }
      }

      /* OPTION HOVER */
      .option:hover {
        /* Default hover */
        background: rgb(166, 232, 205, 0.7);

        &.current {
          &.unmarkable {
            .check {
              background-image: url("/close-black.svg");
            }
          }
        }

        /* For 'radio-rounded' and 'radio-square' themes */
        &.radio-rounded,
        &.radio-square {
          background: none;

          .label {
            color: $baseGreen;
          }
        }

        /* For 'hasImage' theme */
        &.hasImage {
          background: none;

          .label {
            color: #00ad66;
          }
        }
      }

      .empty {
        padding: 5px 25px;
        display: flex;
        align-items: center;

        .icon {
          height: 26px;
          width: 26px;
          margin-right: 10px;
          background-image: url("/puppy/error.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .message {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 16px;
          color: #393939;
          text-align: center;
        }
      }
    }

    /* Creatable box */
    .create {
      box-sizing: border-box;
      height: 50px;
      padding: 0 25px;
      border-top: 0.5px solid rgb(57, 57, 57);
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        background-color: #a6e8cd;
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: center;

        >div {
          width: 16px;
          height: 16px;
          background-image: url("/add-icon.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .title {
        font-family: Circular Std Book;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #393939;
      }
    }
  }

  &.hasErrorMessage {
    padding-bottom: 25px;

    .optionsContainer {
      top: calc(100% - 10px);
    }
  }
}

/* CREATABLE MODAL */
.background {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: rgb(57, 57, 57, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  overflow-x: hidden;

  @include mobile {
    padding: 15px;
  }

  .modal {
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    padding: 40px;
    margin: auto auto;
    background: #ffffff;
    box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
    border-radius: 10px;
    position: relative;

    .close {
      width: 24px;
      height: 24px;
      background-image: url("/close_modal.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      position: absolute;
      top: 23px;
      right: 23px;
    }

    .title {
      margin: 0;
      font-family: Circular Std Book;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;

      @include mobile {
        font-size: 20px;
        line-height: 25px;
        text-align: center;
      }
    }

    .description {
      margin: 0;
      margin-top: 10px;
      font-family: Circular Std Book;
      font-size: 16px;
      line-height: 20px;
      color: #393939;

      @include mobile {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }

      span {
        font-weight: bold;
      }
    }

    input {
      width: 100%;
      margin-top: 20px;

      @include mobile {
        margin-top: 10px;
      }
    }

    .example {
      box-sizing: border-box;
      width: 100%;
      padding: 0 30px;
      margin-top: 10px;
      font-family: Circular Std Book;
      font-size: 14px;
      line-height: 18px;
      color: #393939;

      @include mobile {
        padding: 0;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }
    }

    .buttons {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;

      button {
        height: 50px;
        padding: 0 25px;
        border-radius: 25px;
        width: calc((100% - 20px) / 2);
        font-size: 18px;

        @include mobile {
          height: 40px;
          padding: 0 20px;
          border-radius: 20px;
          font-size: 16px;
        }
      }
    }
  }
}